import { convertToLabelValue } from "./convertToLabelValue"
import { generateInitialValues } from "../../../services/context"
import { getSignedInUser } from "../../Auth/services/user"

import { isBrowser } from "../../../services/general"

export const sourceProfileFormSchema = ({ formFields, moduleName, state }) => {
  let initialValues = {}
  let userData = {}
  let address = state?.address || {}

  if (isBrowser()) {
    switch (moduleName) {
      case "edit":
        userData = getSignedInUser()?.userData
        userData.birthday.month = convertToLabelValue(
          userData.birthday,
          "month"
        )
        userData.birthday.date = convertToLabelValue(userData.birthday, "date")
        userData.birthday.year = convertToLabelValue(userData.birthday, "year")
        userData.emergencyContact.relationship = convertToLabelValue(
          userData.emergencyContact,
          "relationship"
        )
        userData.site = convertToLabelValue(userData, "site")

        formFields = formFields.filter(
          (field) =>
            field.name !== "-" &&
            field.name !== "detailsCorrect" &&
            field.name !== "termsAndConditions" &&
            field.name !== "address"
        )
        initialValues = {
          ...generateInitialValues({
            fields: formFields,
          }),
          ...userData,
          emergencyContactName: userData.emergencyContact.name,
          emergencyContactRelationship: userData.emergencyContact.relationship,
          emergencyContactNumber: userData.emergencyContact.mobileNumber,
          emergencyContactEmail: userData.emergencyContact.email
        }
        break
      case "add-address":
        formFields = formFields.filter((field) => field.name === "address")
        initialValues = {
          ...generateInitialValues({
            fields: formFields,
          }),
        }
        break
      case "edit-address":
        // renders twice idk why
        if (!address.city.value) {
          address.city = convertToLabelValue(address, "city")
          address.province = convertToLabelValue(address, "province")
          address.barangay = convertToLabelValue(address, "barangay")
        }

        formFields = formFields.filter((field) => field.name === "address")
        initialValues = {
          ...generateInitialValues({
            fields: formFields,
          }),
          address,
        }
        break
      case "update-password":
        initialValues = {
          ...generateInitialValues({
            fields: formFields,
          }),
        }
        break
      default:
        formFields = []
    }
  }

  return { formFields, initialValues }
}
