import firebase from "firebase"
import { navigate } from "gatsby"
import { isBrowser } from "services/general"
import { addAddress, editAddress } from "./addresses"
import { getSignedInUser } from "../../Auth/services/user"
import { 
  GATSBY_MAKE_UPDATE_PROFILE,
  GATSBY_ENV
} from "gatsby-env-variables"

import axios from "axios"

export const handleProfileEditSubmit = (payload) => {
  let { setLoading, userData, dispatch } = payload
  switch (payload.module) {
    case "update-password":
      handlePasswordUpdate({
        ...payload,
        callback: () => {
          setLoading(false)
          dispatch({
            type: "SHOW_TOAST",
            payload: {
              message: `Password successfully updated.`,
              color: "success",
            },
          })
          navigate("/profile")
        },
        errorCallback: () => {
          setLoading(false)
        },
      })
      break

    case "edit":
      editProfile({
        ...payload,
        id: userData.id,
        callback: () => {
          setLoading(false)
          dispatch({
            type: "SHOW_TOAST",
            payload: {
              message: `Profile successfully updated.`,
              color: "success",
            },
          })
          navigate("/profile")
        },
        errorCallback: () => {
          setLoading(false)
        },
      })
      break

    case "add-address":
      addAddress({
        ...payload,
        callback: () => {
          setLoading(false)
          dispatch({
            type: "SHOW_TOAST",
            payload: {
              message: `Address successfully added.`,
              color: "success",
            },
          })
          navigate("/profile")
        },
      })
      break

    case "edit-address":
      editAddress({
        ...payload,
        callback: () => {
          setLoading(false)
          dispatch({
            type: "SHOW_TOAST",
            payload: {
              message: `Address successfully updated.`,
              color: "success",
            },
          })
          navigate("/profile")
        },
      })
      break

    default:
      break
  }
}

export const handlePasswordUpdate = async ({
  values,
  setMessage,
  callback,
  errorCallback,
}) => {
  let { userData } = getSignedInUser()
  let currentUserEmail = userData?.email

  try {
    await firebase
      .auth()
      .signInWithEmailAndPassword(currentUserEmail, values?.oldPassword)
    let user = firebase.auth().currentUser
    await user.updatePassword(values?.newPassword)

    // remove force reset
    await firebase
      .firestore()
      .collection("users")
      .doc(userData?.id)
      .update({ forceReset: false })

    if (callback) callback()
  } catch (error) {
    if (errorCallback) errorCallback()

    switch (error?.code) {
      case "auth/wrong-password":
        setMessage({
          type: "danger",
          content: {
            code: error.code,
            message: "The old password you have entered is incorrect.",
          },
        })
        break

      default:
        setMessage({
          type: "danger",
          content: { code: error.code, message: error.message },
        })
    }
  }
}

export const editProfile = async ({
  id,
  values,
  callback,
  userData,
  setMessage,
  errorCallback,
}) => {
  try {
    if (isBrowser()) {
      let { month, date, year } = values.birthday

      let fieldsToUpdate = {
        firstName: values.firstName,
        lastName: values.lastName,
        mobileNumber: values.mobileNumber,
        department: values.department,
        birthday: {
          month: month.value,
          date: date.value,
          year: year.value,
        },
        company: values.company,
        employeeNumber: values.employeeNumber,
        emergencyContact: {
          name: values.emergencyContactName,
          mobileNumber: values.emergencyContactNumber,
          relationship: values.emergencyContactRelationship.value,
          email: values.emergencyContactEmail
        },
        personalEmail: values?.personalEmail,
        sex: values.sex,
        site: values?.site?.value ? values.site : userData.site? userData.site.value : {},
        maxicareCardNumber: values?.maxicareCardNumber
      }

      await firebase
        .firestore()
        .collection("users")
        .doc(id)
        .update(fieldsToUpdate)

      let newUserData = { ...userData, ...fieldsToUpdate }
      sessionStorage.setItem("userData", JSON.stringify(newUserData))

      await axios.post(GATSBY_MAKE_UPDATE_PROFILE, {
        ...newUserData,

        website: "MGX Teleconsult Website",
        environment: GATSBY_ENV === "production" ? "production" : "develop",
      })

      if (callback) callback()
    }
  } catch (error) {
    if (errorCallback) errorCallback()
    setMessage({
      type: "danger",
      content: { code: error.code, message: error.message },
    })
  }
}
