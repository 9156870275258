import React, { useState, useContext, Fragment } from "react"
import { Formik, Form } from "formik"

import Layout from "layout"
import Message from "elements/Message"
import Container from "layout/Container"
import ActionButtons from "elements/ActionButtons"
import Section from "elements/Section"

import { handleProfileEditSubmit } from "./services/profile"
import { getSignedInUser } from "../Auth/services/user"
import { AppContext } from "../../context/AppContext"
import { navigate } from "gatsby"
import { useMentalHealthFormFields } from "../Enrollment/hooks/useAirtableMHFormFields"

import { generateFormField } from "elements/Form/services/form"
import { isObjectEmpty } from "../../services/general"
import DeleteAddressModal from "./Addresses/DeleteAddressModal"
import { sourceProfileFormSchema } from "./utils/sourceProfileFormSchema"

const ProfileForm = (props) => {
  const { dispatch } = useContext(AppContext)
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState({})

  let { pageContext, location } = props
  let { backPath, module } = pageContext

  let forceReset = location?.state?.forceReset
  let userData = {}

  if (module.name === "edit") {
    userData = getSignedInUser()?.userData
  }
  let exclude = ["accessCode", "employeeId", "email"]

  let hasSiteOptions = userData?.domain?.siteOptions?.length
  if (!hasSiteOptions) exclude = ["site", ...exclude]

  let { formFields, initialValues } = sourceProfileFormSchema({
    formFields: pageContext.formFields.filter(
      (field) => !exclude.includes(field.name)
    ),
    moduleName: module.name,
    state: location.state,
  })

  if (hasSiteOptions) {
    let siteOptions = userData?.domain?.siteOptions

    formFields.forEach((field, index) => {
      if (field.name === "site") {
        if (siteOptions && siteOptions?.length > 0) {
          field.options = siteOptions
        } else formFields.splice(index, 1)
      }
    })
  }

  const handleSubmit = (values, { resetForm }) => {
    setLoading(true)

    handleProfileEditSubmit({
      values,
      resetForm,
      setMessage,
      setLoading,
      userData,
      dispatch,
      module: module.name,
    })
  }

  const handleDeleteAddress = ({ address, index }) => {
    dispatch({
      type: "SHOW_MODAL",
      payload: {
        heading: "Delete Address",
        isCard: true,
        headerClass: `has-text-danger has-background-danger-light has-text-weight-bold header-size-text`,
        content: (
          <DeleteAddressModal
            address={address}
            index={index}
            handleDeleteCallback={() => {
              navigate("/profile")
            }}
          />
        ),
      },
    })
  }

  let { sectionFormFields, validationSchema } = useMentalHealthFormFields({
    formFields,
  })

  return (
    <Layout
      title={module.title}
      seoTitle={module.seoTitle}
      subtitle={module.subtitle}
      isPrivate
      isPatient
      pageContext={pageContext}
      polygonStyle="updatePassword"
    >
      <Container isCentered>
        {forceReset && (
          <Message color="warning">
            For your account security, we require you to change your password.
          </Message>
        )}
        <Formik
          initialValues={{ ...initialValues }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          enableReinitialize={true}
        >
          {({ values, setFieldValue, errors }) => (
            <Form className="pb-2">
              {module.name === "edit-address" && !values?.address?.primary && (
                <button
                  type="button"
                  className="button is-danger mb-2"
                  onClick={() => {
                    handleDeleteAddress({
                      index: location?.state?.index,
                      address: location?.state?.address,
                    })
                  }}
                >
                  Delete this address
                </button>
              )}
              {sectionFormFields
                .sort(
                  (firstFormField, secondFormField) =>
                    firstFormField.order - secondFormField.order
                )
                .map((section) => (
                  <Section
                    title={section?.section}
                    subtitle={section?.subtitle || ""}
                    id={section?.sectionId || ""}
                  >
                    {section?.message && (
                      <Message color="light">{section?.message}</Message>
                    )}
                    {section?.fields.map((field) => {
                      if (!field?.referenceAnswer) {
                        return (
                          <Fragment>
                            {generateFormField({
                              formFields: section?.fields,
                              formField: field,
                              values,
                              setFieldValue,
                            })}
                            {!!field?.addDividerAfterField && (
                              <hr className="has-background-light" />
                            )}
                          </Fragment>
                        )
                      }
                      return null
                    })}
                  </Section>
                ))}

              {!isObjectEmpty(errors) && (
                <Message>
                  You may have missed some required fields. Please scan through
                  the form and check if your information is complete.
                </Message>
              )}

              {message?.content && (
                <Message color={message?.type}>
                  {message?.content?.message || message?.content}
                </Message>
              )}

              <ActionButtons
                back={{ label: "Back", link: backPath }}
                submit={{ label: "Update", loading }}
              />
            </Form>
          )}
        </Formik>
      </Container>
    </Layout>
  )
}

export default ProfileForm
